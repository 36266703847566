import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { get } from 'lodash';
import Card from 'reactstrap/lib/Card';

import {
  BuildPriceCreativeConfigDefaultProps,
  BuildPriceCreativeConfigPropTypes,
} from 'client/data/models/native-ads-creative-config';
import {
  CommonAdDefaultProps,
  CommonAdPropTypes,
  SiteServedAdDefaultProps,
  SiteServedAdPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';

import { BUILD_PRICE_AD } from 'site-modules/shared/components/native-ad/utils/constants';
import { definePlatform } from 'site-modules/shared/components/native-ad/utils/utils';
import { siteServedAdTrackingWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-tracking-wrapper/site-served-ad-tracking-wrapper';
import { AdLabel } from 'client/site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { Disclaimer } from 'client/site-modules/shared/components/native-ad/native-ad-components/disclaimer/disclaimer';
import { DfpTracking } from 'client/site-modules/shared/components/native-ad/utils/dfp-tracking';

import { BuildPriceAtAGlance } from './buildprice-at-a-glance';
import { BuildPriceFeatures } from './buildprice-features';

import './buildprice-site-served-ad.scss';

export function BuildPriceSiteServedAdUI({
  adRef,
  adClickTrackingListener,
  adLoadTrackingListener,
  adTrackers,
  customTargeting,
  vehicle,
  currentVehicle,
  creativeConfig,
  mobile,
  position,
  wrapperClass,
}) {
  const {
    siteServedAdsUpdater,
    creativeConfigData,
    traffickingData: { clickTracker },
    shuffleFeatures,
  } = creativeConfig;

  const {
    headline,
    cta,
    disclaimerCopy,
    lineItemId,
    siteServedCreativeId,
    target,
    vehicleInfo,
    features,
  } = creativeConfigData;

  const loadTrackingListener = useCallback(
    event => {
      adLoadTrackingListener(event, cta);
    },
    [adLoadTrackingListener, cta]
  );

  if (!headline || !cta) {
    return null;
  }

  const targetVehicle = currentVehicle || vehicle;
  const ctaURL = clickTracker[definePlatform(mobile)];
  const ctaTarget = target || '_blank';
  const modelYear = get(vehicleInfo, 'year', '');

  const nativeStyle = BUILD_PRICE_AD.NATIVE_STYLE;

  const isFeaturesBuildPrice = get(features, 'length');

  return (
    <Card
      className={classnames('buildprice-site-served pos-r', wrapperClass, {
        'w-100': !mobile,
        'show-dropshadow': !isFeaturesBuildPrice,
      })}
      data-tracking-parent={nativeStyle}
      data-viewability={`${nativeStyle}-${position}`}
      data-make-name={get(targetVehicle, 'make.name', '')}
      data-model-name={get(targetVehicle, 'model.name', '')}
      data-model-year={modelYear}
      innerRef={adRef}
      style={{ overflow: 'hidden' }}
    >
      <DfpTracking
        lineItemId={lineItemId}
        siteServedCreativeId={siteServedCreativeId}
        mobile={mobile}
        nativeStyle={nativeStyle}
        position={position}
        currentVehicle={currentVehicle}
        customTargeting={customTargeting}
        slotRenderEndListener={loadTrackingListener}
        siteServedAdsUpdater={siteServedAdsUpdater}
        verticalOffset={mobile ? '-216px' : '-150px'}
      />
      {adTrackers}
      <a
        className={classnames('site-served-ad-link no-focus text-decoration-none d-block px-1_25 py-1_5', {
          'is-gradient-bg': !isFeaturesBuildPrice,
        })}
        href={ctaURL}
        rel="noopener noreferrer"
        target={ctaTarget}
        onClick={adClickTrackingListener}
      >
        <AdLabel />
        {isFeaturesBuildPrice ? (
          <BuildPriceFeatures creativeConfigData={creativeConfigData} features={shuffleFeatures} mobile={mobile} />
        ) : (
          <BuildPriceAtAGlance creativeConfigData={creativeConfigData} mobile={mobile} />
        )}
      </a>
      {disclaimerCopy && (
        <Disclaimer
          disclaimerText={disclaimerCopy}
          absoluteBottomValue="-2rem"
          buttonClasses="pos-a bottom-0 right-0"
        />
      )}
    </Card>
  );
}

BuildPriceSiteServedAdUI.propTypes = {
  ...SiteServedAdPropTypes,
  customTargeting: CommonAdPropTypes.customTargeting,
  creativeConfig: BuildPriceCreativeConfigPropTypes,
  wrapperClass: PropTypes.string,
};

BuildPriceSiteServedAdUI.defaultProps = {
  ...SiteServedAdDefaultProps,
  customTargeting: CommonAdDefaultProps.customTargeting,
  creativeConfig: BuildPriceCreativeConfigDefaultProps,
  wrapperClass: null,
};

export const BuildPriceSiteServedAd = siteServedAdTrackingWrapper(BuildPriceSiteServedAdUI);
